.restart {
    display: inline-flex;
    justify-content: flex-end;
    gap: 0.5rem;
    margin-block: auto;
    cursor: pointer;
  
    &__text {
      color: #000;
      font-family: "Poppins Regular";
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.375rem;
      letter-spacing: 0.0125rem;
      margin-block: auto;
    }
  
    &__button {
      width: 0.86238rem;
      height: 0.86238rem;
    }
  }
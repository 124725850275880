@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

.quiz_fact_container {
  width: 20rem;
  margin-right: 5rem;
  display: inline-flex;
  padding: 2rem 1.5rem;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  border-radius: 0.9375rem;
  background: var(--Foundation-Second-Neutral-900, #524F47);
  box-shadow: 0px 0px 41px -1px rgba(97, 97, 97, 0.15);
  
  @media (max-width: 768px) {
    display: none;
  }
}

.badge_logo_bright svg {
  width: 7.5rem;
  height: 7.5rem;
}

.head_text {
    color: var(--Foundation-White, #FFF);
    font-family: 'Poppins Regular';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem; 
    margin: 0;

    @media only screen and (max-width: 767px) {
        font-size: 1rem;
    }
}

.info_text {
    color: var(--Foundation-White, #FFF);
    text-align: center;
    font-family: 'Poppins Light';
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.375rem;
    letter-spacing: 0.0125rem;
    margin: 0;
    @media only screen and (max-width: 767px) {
        font-size: 0.75rem;
    }
}

.form_field_with_label {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  font-size: 16px;
  padding: 0rem 0.5rem;

  &__container {
    display: flex;
    gap: 0.5rem;
  }

  &__text {
    text-align: left;
    color: #000;
    font-family: "Poppins Regular";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: 0.0125rem;
    margin: 0.5rem 0rem;
  }
}

@import "../../styles/partials/variables.scss";

.gallery-page {
  /*background-color: white;*/
  display: flex;
  flex-grow: 1;
  z-index: 0;
}

@media screen and (max-width: 1020px) {
  .gallery-page {
    flex-direction: column;
  }
}

@import "../../styles/partials/mixins.scss";

.quiz-input-container {
    display: flex;
    position: relative;
    width: 100%;
}

.clear_input_btn {
  padding-block-end: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;  
}
  
  .quiz-input-list-menu {
    position: absolute;
    top: 100%;
    left: 2%;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    margin-top: 10px;
  }
  

  .quiz-input-list-item {
    @include flex-row;
    padding: 8px 10px;
    cursor: pointer;
    flex-wrap: wrap;
    gap : 0.5rem;
  }
  
  .quiz-input-list-item:hover {
    background-color: #f0f0f0;
  }
  
  .quiz-input-list-sublist {
    margin-left: 20px;
  }
  
  .sub-item {
    font-size: 0.9em;
    color: #555;
    white-space: wrap;
    margin-block: auto;
    white-space: wrap;
  }
  
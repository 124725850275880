@import "../../styles/partials/mixins.scss";
@import "../../styles/partials/variables";

.container {
  width: 100%;
}

.vehicle_header {
  margin-block: 0 0.5rem;
  font-size: 1rem;
  font-family: 'Poppins SemiBold';
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; 
}

.dropdown-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.dropdown-group {
  width: 75%;
  @media (max-width: 768px) {
    width: 90%;
  }
}

label {
  display: block;
  margin: 0.5rem 0rem;
  color: $black;
  font-family: 'Poppins Regular';
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem; 
  letter-spacing: 0.0125rem;
}

.dropdown {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
  border-radius: 0.5rem;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none"><path d="M9.03857 16.5L12.0386 19.5L15.0386 16.5" stroke="black" stroke-linecap="round" stroke-linejoin="round"/><path d="M9.03857 8.5L12.0386 5.5L15.0386 8.5" stroke="black" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  background-repeat: no-repeat;
  background-position: right 8px top 50%;
  cursor: pointer;
}

.dropdown option[disabled]:first-child {
  color: #aaa
}

.add-more-car-btn-container {
  width: 30%;

  @media only screen and (max-width: 768px) {
    width: 50%;
  }
}

@import "../../styles/partials/mixins.scss";
@import "../../styles/partials/variables";

.combine-fields {
    @include flex-row;
    gap : 0.25rem;
    width: 100%;

    @media only screen and (max-width: 1020px) {
        @include flex-column;
    }
}

.dropdown-group {
    padding: 0rem 0.5rem;
}
@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

.restart-btn-pos {
    @include flex-row;
    justify-content: flex-end;
    margin-block-start : 1rem;
    margin-inline: 2rem;
}

.page-no-match {
  @include flex-column-center;
  gap: 1rem;
  text-align: center;
  margin-block: 1rem 0;
  margin-inline: auto;
  font-family: "Poppins Regular";
  width: 40vw;

    @media only screen and (max-width: 767px) {
      width: 80vw;  
    }

    @media only screen and (min-width: 768px) and (max-width : 1024px) {
        width : 65vw;
    }

  &__header {
    @include header;
    font-size: 1rem;
  }

  &__subheader {
    @include subheader;
    font-size: 2.25rem;

    @media only screen and (max-width: 767px) {
        font-size: 1.75rem;
    }

    @media only screen and (min-width: 768px) and (max-width : 1024px) {
        font-size: 1.25rem;
    }
  }

  &__description {
    @include body;
    margin-inline: 1rem;
    color: var(--Foundation-Neutrals-600, #57544D);
    font-style: normal;
    font-weight: 300;
    line-height: 1.375rem;
    letter-spacing: 0.0125rem;
  }

  &__filler {
    width: 100%;    
    height: auto;
  }
}


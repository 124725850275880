@import "../../styles/partials/mixins.scss";

// $cardWidth: 11.625rem;
$gap: 0.8rem;
// $maxCardsPerRow: 4;

.combined-question-container {
  display : flex;
  flex-direction : column; 
  gap : 2rem;
}

.combined-question-subcontainer {
  display : flex;
  flex-direction : column; 
  gap : 0.25rem;
}

.combined-option-title {
  font-family: 'Poppins SemiBold';
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  @media (max-width: 768px) {
    font-size: 12.5px;
  }
}

.two_cards {
  $cardWidth: 11.9rem;
  $maxCardsPerRow:2;
  width: #{($cardWidth * $maxCardsPerRow) + $gap};
}

.two_wider_cards {
  $cardWidth: 13.625rem;
  $maxCardsPerRow:2;
  width: #{($cardWidth * $maxCardsPerRow) + $gap};
}

.three_cards{
  $cardWidth: 11.9rem;
  $maxCardsPerRow: 3;
  width: #{($cardWidth * $maxCardsPerRow) + $gap * ($maxCardsPerRow - 2)};
}

.four_cards {
  $cardWidth: 11.9rem;
  $maxCardsPerRow:4;
  width: #{($cardWidth * $maxCardsPerRow) + $gap * ($maxCardsPerRow - 2)};
}

.four_wider_cards {
  $cardWidth: 13.625rem;
  $maxCardsPerRow:4;
  width: #{($cardWidth * $maxCardsPerRow) + $gap * ($maxCardsPerRow - 1)};
}

.quiz {
  box-sizing: border-box;
  height: 100vh;
  padding: 1rem 3rem;
  @include dashboard-desktop;
  background-color: #fffefa;

  @media (max-width: 1200px) {
    height: 85vh;
    padding: 1rem 1rem;
  }

  @media (max-width: 768px) {
    height: auto;
    padding: 1rem 1rem;
  }

  &__filler {
    @include flex-column;
    justify-content: space-between;
    flex-grow: 1;
    flex-shrink: 0;
  }

  &__intro {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-height: 40%;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    &--titles {
      display: flex;
      flex-direction: column;
      max-width: 100vw;
      @media (max-width: 768px) {
        max-width: 70vw;
        margin: 1rem 0rem;
      }
    }
  }

  &__options_cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: $gap;
    padding: $gap;
    border-radius: 0.9375rem;
    background: var(--foundation-secondary-100, #edeae5);
    box-shadow: 0px 0px 41px -1px rgba(97, 97, 97, 0.15);

    @media (max-width: 768px) {
      $maxCardsPerRow: 2;
      $cardWidth: 10;
      width: #{($cardWidth * $maxCardsPerRow) + $gap * ($maxCardsPerRow - 1)};
    }
    
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__buttons {
    display: flex;
    margin: 0.5rem 0rem;
    @media (max-width: 768px) {
      margin: 1rem 0rem;
    }
  }
}

.header {
  @include header;
  font-size: 1rem;
}

.subheader {
  @include subheader;
  @include flex-row;
  gap : 0.25rem;
  font-size: 2rem;

  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
}

.info_svg_container {
  margin-block: auto;
}

.info_svg_container svg {
  margin-block: auto;
  width: 80%;
  height: auto;
}

.option_header {
  margin-block-end: 0.5rem;
  font-size: 1.25rem;
  font-family: 'Poppins SemiBold';
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; 
}

.question_divider_column {
  @include flex-column;
  gap :  1.5rem;
  width: 60%;
}

.question_divider_row {
  @include flex-row;
  width: 100%;
  justify-content: space-between;
}


.subtext {
  font-family: "Poppins Light";
  font-size: 1rem;
  color: var(--foundation-black, #383838);
  margin-top: 0;
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
}

.budget-title {
  font-family: "Poppins Medium";
  color: $black;
  font-size: 1.25rem;
  margin-bottom: -3rem;
}

.badge__logo {
  padding-right: 2rem;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    display: none;
  }
}

.badge__logo svg {
  height: 100px;
}

@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

.value_ranking_main {
  @include flex-column;
  gap : 1.25rem;
  padding: 0.5rem 0.25rem; 


  &__options {
    @include flex-row;
    gap : 1.25rem;

    &--rank {
      display: flex;
      align-items: flex-start;
      border-radius: 0.5rem;
    }

    &--drag_div {
        display: flex;
        padding: 0.75rem 1.25rem;
        padding-inline-end: 2.5rem;
        align-items: center;
        gap: 0.75rem;
        flex: 1 0 0;
        border-radius: 0.75rem;
        background: var(--Foundation-White, #FFF);
    }
  }

}

.value_ranking_component_text {
    color: #000;
    text-align: center;
    font-family: 'Poppins SemiBold';
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
}

.option_text {
    font-family: 'Poppins Regular';
    margin: 0;
}

.numbering {
  display: flex;
  padding: 0.75rem 0.65625rem;
  height : 1.5rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: #FFF;
}

.arrow-box {
  display: flex;
  padding: 0 0.375rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: var(--Foundation-Accent-500, #BDDFFF);
}
@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

.cc-liked-card-match {
  @include flex-row;
  justify-content: center;

  &__circle {
    position: relative;
  }
  &__circle svg {
    width: 55px;
    height: 55px;
    margin-inline: auto;

    @media only screen and (max-width: 767px) {
      width: 35px;
      height: 35px;
    }
  }
}

.cc-liked-card-match-stamp {
  margin-inline: auto;
  padding: 0.5rem;
  position: absolute;
  bottom: 12px;
  left: 4px;

  &__number {
    @include normal-text-attributes;
    color: $black;
    font-family: "Poppins Bold";
    margin: 0;

    @media only screen and (max-width: 767px) {
      font-size: 0.75rem;
    }
  }

  @media only screen and (max-width: 767px) {
    left : -2px;
    bottom : 7px;
  }
}

.cc-match {
  &__text {
    display: flex;
    flex-direction: column;
    margin-block: 0.25rem;

    &--row {
      display: flex;
      flex-direction: row;
      gap: 0.25rem;
    }
  }
}

.cc-text-normal {
  margin: 0px;
  color: $black;
  font-size: 0.875rem;
  font-family: "Poppins SemiBold";
  margin-block: auto;
  @media only screen and (max-width: 767px) {
    font-size: 0.625rem;
  }
}

.cc-text-bold {
  margin: 0px;
  color: $black;
  font-size: 1rem;
  font-family: "Poppins Bold";
  @media only screen and (max-width: 767px) {
    font-size: 0.75rem;
  }
}
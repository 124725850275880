.budget-divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;
}

.budget-divider::before,
.budget-divider::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #000;
}

.budget-divider:not(:empty)::before {
  margin-right: 0.25em;
}

.budget-divider:not(:empty)::after {
  margin-left: 0.25em;
}

@import "../../styles/partials/_variables.scss";

.form-group {
  position: relative;
  width: 100%;
}

.form-field {
  border-radius: 7px;
  border: none;
  font-family: "Poppins Light";
  background-color: white;
  padding: 0.5rem 1rem;
  width: -webkit-fill-available;
  color: $neutral900;
  font-size: 16px;

  &::placeholder {
    padding: 10px 0px;
  }

  &:focus {
    outline: none;
  }
}

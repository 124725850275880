@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

.offer-container {
  display: inline-flex;
  margin-block : 2rem;
  width: calc(100vw - 10.05rem);
  padding: 2.125rem 4.3125rem 2.125rem 4.66344rem;
  justify-content: space-between;
  align-items: center;
  background: var(--Foundation-Mae-Yellow-100, #ffeec5);
  flex: 1 0 0;

  &--text {
    width: 75%;
    color: $black;
    font-family: "Poppins Regular";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.326rem;
    letter-spacing: 0.01063rem;

    @media only screen and (max-width: 767px) {
      font-size: 0.875rem;
    }
  }

  &--button {
    @include flex-column-center;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
    padding: 0.5rem 1rem;
  }

  @media only screen and (min-width: 768px) and (max-width: 1080px) {
    width: calc(100vw - 7.1rem);
    padding: 2rem 3rem;
  }
}
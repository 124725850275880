@import "../../styles/partials/mixins.scss";

.progress-wrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
  width: 25rem;
  height: 0.3rem;
  border-radius: 0.05rem;
  background: var(--foundation-secondary-200, #e4e0d8);

  &__bar {
    height: 0.3rem;
    background: var(--foundation-secondary-800, #6c675e);
  }
}

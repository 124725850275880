@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

.landing_page {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fffefa;
  width: 100%;
  box-sizing: border-box;
  height: 100vh;

  @media (max-width: 1024px) {
    flex-direction: column;
    height: auto;
    padding-top: 2rem;
  }

  &__filler {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 0;
    width: 50%;
    overflow: hidden;

    @media (max-width: 1024px) {
      width: 100%;
      justify-content: center;
    }

    &::after {
      right: 0;
      bottom: 0;
      pointer-events: none;
      content: "";
      position: absolute;
      width: 30%;
      height: 100%;
      opacity: 0.3;
      -webkit-filter: blur(150px);
      filter: blur(150px);
      background-image: linear-gradient(43deg, $yellow500 22%, #ff9d43 89%);
      z-index: 0;
    }

    &--image {
      height: 80%;
      width: auto;
      flex-shrink: 0;
      z-index: 1;
      margin-right: -5%;

      @media (max-width: 1024px) {
        justify-content: center;
        width: 90%;
        height: auto;
        margin-bottom: 1rem;
        margin-right: 0;
      }

      @media (min-width: 1024px) and (max-width: 1220px) {
        height: 60%;
      }
    }
  }
}
  
.product_info {
    @include flex-column;
    justify-content: center;
    gap: 1rem;
    width: 50%;
    box-sizing: border-box;
    padding: 0rem 4rem;

    @media (max-width: 1024px) {
      width: 100%;
      align-items: center;
    }

    &__dealership_logo {
      width: 214px;

    }

    &__creator_section {
      @include flex-row;
      gap : 0.56rem;
    }

    &__logo {
      width: 4.78294rem;
      height: 1.73881rem;
    }

    &__text {
      color: #000;
      font-family: "Poppins Regular";
      font-size: 1rem;
      font-style: normal;
      font-weight: 300;
      line-height: 1.375rem;
      letter-spacing: normal;

      &--margins {
        margin-block-start: 0rem;
        margin-block-end: 0.5rem;
        
        @media (max-width: 1024px) {
          margin-block-start: 0.5rem;
        }

      }

      @media (max-width: 767px) {
        text-align: center;
        font-size: 1rem;
      }

      @media (min-width: 768px) and (max-width: 1024px) {
        text-align: center;
        font-size: 1.2rem;
        width: 80%;
      }
    }

    &__main_text {
      color: #383838;
      font-family: "Poppins SemiBold";
      font-size: 2.5rem;
      font-weight: 600;
      line-height: 2.625rem;

      @media (max-width: 767px) {
        font-size: 1.75rem;
        text-align: center;
      }
      @media (min-width: 768px) and (max-width : 1024px) {
        text-align: center;
      }
    }

    &__button {
      width: 40%;
      margin-bottom: 1rem;

      @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 2rem;
      }

      @media (min-width: 768px) and (max-width: 1024px) {
        width: 80%;
        margin-bottom: 2rem;
      }
    }
  }



@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.blured {
  filter: blur(2px);
}

.no-scroll {
  overflow: hidden;
}

.as-comparechart {
  @include flex-column;
  padding: 3vw;
  padding-inline: 3vw;
  gap: 2rem;

  &__title-container {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-family: "Poppins Regular";
    font-size: 1rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.625rem;
    letter-spacing: normal;
    margin: 0;
  }

  &__offer-container {
    display: inline-flex;
    margin-inline: -3vw;
    padding: 2.125rem 4.3125rem 2.125rem 4.66344rem;
    justify-content: space-between;
    align-items: center;
    background: var(--Foundation-Mae-Yellow-100, #ffeec5);

    &--text {
      width: 50%;
      color: #000;
      font-family: "Poppins Regular";
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.326rem; /* 117.865% */
      letter-spacing: 0.01063rem;
    }

    &--button {
      @include flex-column-center;
      width: 15%;
    }
  }

  &__restart {
    display: inline-flex;
    justify-content: flex-end;
    gap: 0.5rem;
    margin-block: auto;

    &--text {
      color: #000;
      font-family: "Poppins Regular";
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.375rem;
      letter-spacing: 0.0125rem;
    }

    &--button {
      width: 0.86238rem;
      height: 0.86238rem;
    }
  }

  &__container {
    @include flex-column;
    gap: 2rem;
  }
}

.chart-container > * {
  flex: 1;
}

.chart-container {
  @include flex-row;
  gap: 1.5rem;

  &__name-text {
    color: $black;
    font-family: "Poppins Regular";
    font-size: 1rem;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
  }

  &__container {
    @include flex-center;
    padding: 0rem 0.5rem;
  }

  &__label-text {
    color: $neutral500;
    font-family: "Poppins Medium";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    // display: flex;
    // align-items: center;

  }

  &__translate_y {
    transform: translateY(50%);
  }

  &__add_car_sign {
    display: flex;
    justify-content: center;
  }

  &__add_car_sign svg {
    width: 60%;
    height: auto;
  }

  &__label-text-group {
    color: $yellow900;
    font-family: "Poppins Medium";
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.125rem;
  }
}

.img-block {
  border-radius: 0.9375rem;
  border: 1px solid $secondary300;
  display: flex;
  justify-content: center;

  &--img {
    display: block;
    max-width: 100%;
    width: 200px;
    object-fit: contain;
    border-radius: 0.9375rem;
  }
}

.price-block {
  @include flex-center;
  padding: 0 0.5rem;
  gap: 0.2rem;
  color: $black;
  font-family: "Poppins Regular";
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &--currency {
    font-family: "Poppins Medium";
    font-size: 0.8rem;
  }
}

.recommended-trim-block {
  @include flex-column-center;
  padding: 0.75rem 0.25rem;
  border-radius: 0.5rem 0.25rem 0.25rem 0.5rem;
  background-color: #ffc943;
  color: #383838;
  position: relative;

  &--text {
    font-size: 0.8rem;
    font-family: "Poppins Light";
    color: $black;
    margin: 0;
    text-align: center;
  }

  &--icon {
    position: absolute;
    top: -15px;
    right: 0px;
    width: 30px;
    height: auto;
  }
}

.compare-feature-block__inner > * {
  flex: 1;
}

.compare-feature-block {
  @include flex-column;
  gap: 1.5rem;
  width: 100%;

  &__inner {
    display: flex;
    gap: 1.25rem;
  }

  &__text {
    color: $black;
    font-family: "Poppins Medium";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5rem;
    letter-spacing: 0.0125rem;
    text-align: center;
  }
}

.as-divider-line svg {
  width: 90vw;
}

.as-divider-line {
  margin-block: -1rem;
}

.as-requirement_icon {
  width: 30px;
  height: 30px;
}

.as-requirement-container {
  display: flex;
  justify-content: center;
}

.more-features {
  width: 60vw;
  margin: auto;
  display: inline-flex;
  padding: 0.34631rem 0.39581rem;
  justify-content: center;
  align-items: center;
  gap: 0.49475rem;
  border-radius: 3.1665rem;
  border: 0.792px solid var(--Foundation-Black, #383838);
  cursor: pointer;

  &__text {
    color: var(--Foundation-Neutrals-700, #4f4c46);
    font-family: "Poppins Regular";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.0885rem;
    letter-spacing: 0.00988rem;
    margin-block: auto;
  }

  &__add {
    @include flex-column-center;
  }

  &__add svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.popup_position {
  position: absolute;
  top: 0.5rem;
  right: 2rem;
}

.label-with-info {
  @include flex-row-center;
  justify-content: center;
  position: relative;
}

.label-with-info .tooltip {
  visibility: hidden;
  opacity: 0;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 0.5rem;
  position: absolute;
  z-index: 1;
  bottom: calc(100% + 2px);
  // left: 30%;
  // transform: translateX(-30%);
  transition: opacity 0.3s;
  font-family: "Poppins Medium";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
}

.label-with-info svg {
  max-width: 1rem;
  max-height: 0.9rem;
  margin-inline-start: 0.25rem;
  transform: translateY(15%);
}

/* Responsive Web Design */

@media only screen and (max-width: 440px) {
  .as-comparechart {
    gap: 1rem;
    &__title {
      font-size: 1.25rem;
    }
    &__container {
      gap: 1.5rem;
    }

    &__offer-container {
      padding: 0.5rem 1rem;

      &--text {
        width: 60%;
        font-size: 0.85rem;
      }

      &--button {
        width: 35%;
      }
    }

    &__restart {
      &--text {
        font-size: 0.875rem;
      }
    }
  }

  .chart-container {
    &__name-text {
      font-size: 0.75rem;
    }

    &__label-text {
      font-size: 0.75rem;
    }

    &__add_car_sign svg {
      width: 100%;
    }

    &__label-text-group {
      font-size: 0.75rem;
    }
  }

  .img-block {
    &--img {
      height: 100px;
    }
  }

  .price-block {
    font-size: 0.75rem;
    &--currency {
      font-size: 0.6125rem;
    }
  }

  .recommended-trim-block {
    &--text {
      font-size: 0.625rem;
    }

    &--icon {
      width: 20px;
    }
  }

  .compare-feature-block {
    gap: 1.06rem;

    &__text {
      font-size: 0.75rem;
    }
  }

  .as-requirement_icon {
    width: 24px;
    height: 24px;
  }

  .chart-container > * {
    width: 25vw;
    object-fit: contain;
  }

  .compare-feature-block__inner > * {
    width: 25vw;
    object-fit: contain;
  }

  .as-divider-line {
    margin-block: -1.5rem;
  }

  .as-divider-line svg {
    width: 100%;
  }

  .more-features {
    gap: 0.25rem;
    &__text {
      font-size: 0.75rem;
    }

    &__add svg {
      width: 1.2rem;
      height: 1.2rem;
    }
  }

  .popup_position {
    right: 0.5rem;
  }

  .label-with-info .tooltip {
    width: 50vw;
    font-size: 0.75rem;
    // left: 75%;
  }

  .label-with-info svg {
    width: 0.9rem;
    height: 0.9rem;
    margin-inline-start: 0.1rem;
  }
}

@media only screen and (min-width: 441px) and (max-width: 1080px) {
  .as-comparechart {
    &__offer-container {
      padding: 2rem 3rem;

      &--text {
        width: 60%;
        font-size: 1rem;
      }

      &--button {
        width: 30%;
      }
    }
  }

  .chart-container {
    &__name-text {
      font-size: 1rem;
    }
  }

  .img-block {
    &--img {
      max-height: 100px;
    }
  }

  .compare-feature-block {
    gap: 2.06rem;
  }

  .as-divider-line {
    margin-block: -1rem;
  }

  .as-requirement_icon {
    width: 28px;
    height: 28px;
  }

  .more-features {
    &__text {
      font-size: 0.875rem;
    }

    &__add svg {
      width: 1.28744rem;
      height: 1.28744rem;
    }
  }

  .popup_position {
    right: 0.8rem;
  }

  .label-with-info .tooltip {
    // left: 60%;
    width: 40vw;
  }
}
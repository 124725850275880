@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

@media print {

  #no-print-header #no-print-list{
    display: none;
  }

  #print {
    display: block; 
  }

  #second-print{
    display: block; 
  }
}

#print{
  display: none;
  margin:1rem;
}

#second-print {
  display: none;
  margin:1rem;
}

.gallery {
  @include flex-column;
  width: 100%;
  
  &__hero {
    padding: 2rem;

    &--main {
      @include flex-row;
      justify-content: space-between;
    }

    &--header {
      @include header;
    }

    &--title {
      @include subheader;
      padding-top: 1rem;
    }
  }
}

a {
  color : $black;
}


.card-selected {
    border-radius: 0.9375rem;
    border: 1px solid $yellow400;
    padding: 0.5rem;
    background: #fff;
    box-shadow: 0px 0px 9px -1px rgba(97, 97, 97, 0.15),0px 0px 0px 4px rgba(255, 201, 67, 0.3);
}

.empty-selection {
  position: absolute;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.25rem;
  border: 1px solid $black;
  right : -0.25rem;
  top: -0.75rem;
}


.liked-list {
  @include flex-column;
  width: 100%;
  box-sizing: border-box;
  padding: 0rem 2rem;

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
  }
}

.liked-card {
  width: 15rem;
  flex-grow: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0.5em;
  border-radius: 15px;

  &__featured {
    background-color: #FFDB81;
    padding: 0.3rem 0.5rem;
    border-radius: 15px;
    margin: 0.5rem 0rem 0rem 0rem;
    color: #383838;
    font-size: 0.7rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &__featured-orange {
    background-color: #FFA95B;
    padding: 0.3rem 0.5rem;
    border-radius: 15px;
    margin: 0.5rem 0rem 0rem 0rem;
    color: #383838;
    font-size: 0.7rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }


  &__thumbnail {
    border-radius: 0.9375rem;
    background: #fff;
    width: 100%;
    box-sizing: border-box;
    position: relative;

    &--img {
      width: 100%;
    }


  }

  &__details {
    @include flex-column;
    gap: 0.25rem;
    
    &--Used {
      background-color: #8C6F25;
      color: white;
      border-radius: 20px;
      padding: 0rem 0.5rem;
      font-size: 0.7rem;
      text-align: center;
      margin: 0.2rem 0.2rem 0.2rem 0rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    &--New {
      border: 2px solid var(--foundation-neutrals-100, #8C6F25);
      color: black;
      border-radius: 20px;
      padding: 0.25rem 0.5rem;
      font-size: 0.7rem;
      text-align: center;
      margin: 0.2rem 0.2rem 0.2rem 0rem;
      box-sizing: content-box;
    }

    &--Other {
      border: 2px solid var(--foundation-neutrals-100, #FEC942);
      color: black;
      border-radius: 20px;
      padding: 0.25rem 0.5rem;
      font-size: 0.7rem;
      text-align: center;
      margin: 0.2rem 0.2rem 0.2rem 0rem;
      box-sizing: content-box;
    }

    &--title {
      color: var(--Foundation-Black, #383838);
      font-family: 'Poppins Regular';
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.5rem; 
    }

    &--price {
      color: var(--Foundation-Neutrals-500, #706F6E);
      font-family: 'Poppins Regular';
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0.5rem 0rem;
    }


    &--expandFunc {
        background-color: $yellow200;
        padding: 0.3rem 0.5rem;
        border-radius: 15px;
        margin: 0.5rem 0rem 0rem 0rem;
        color: #383838;
        font-weight: 600;
        font-size: 0.7rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    &--link {
      color: var(--Foundation-Neutrals-500, $black);
      font-family: 'Poppins Medium';
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0.5rem 0rem 0rem 0rem;
      text-align: center;
    }

    &--discount {
      width: 1.5rem;
      margin-left: 0.5rem;
    }
  }
}

.liked-card-match {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.12rem;

  &__circle {
    position: relative;
  }
}

.match {
  &__text {
    display: flex;
    flex-direction: column;
    margin-block: 0.25rem;

    &--row {
      display: flex;
      flex-direction: row;
      gap: 0.25rem;
    }
  }
}

.text-normal {
  margin: 0px;
  color: var(--Foundation-Neutrals-500, #706F6E);
  font-size: 0.75rem;
  font-family: "Poppins SemiBold";
}

.text-bold {
  margin: 0px;
  color: var(--Foundation-Neutrals-500, #706F6E);
  font-family: "Poppins Bold";
}

.liked-card-match-stamp {
  display: flex;
  flex-direction: column;
  height: 1.5rem;
  padding: 0.125rem 0.5rem;
  align-items: center;
  width: fit-content;
  position: absolute;
  bottom: 22px;
  left: 3px;

  &__number {
    color: $black;
    font-family: "Poppins Bold";
    font-size: 1.14019rem;
    margin: 0;
  }

  &__text {
    color: $black;
    font-family: "Poppins Light";
    font-size: 0.5rem;
    margin: -9px;
  }

  &__percent {
    font-size: 0.5rem;
  }
}



@media only screen and (max-width: 440px) {

  .liked-card {
    width: 100%;
  }

  .liked-card-match {
    gap: 0.5rem;

    &__circle svg {
      width: 35px;
      height: 35px;
    }
  }

  .liked-card-match-stamp {
    bottom: 8px;
    left: 1px;
    gap: 0.25rem;

    &__number {
      font-size: 0.54019rem;
    }

    &__text {
      font-size: 0.5rem;
    }

    &__percent {
      font-size: 0.5rem;
    }
  }
}

@media only screen and (max-width: 1020px) {

  .liked-list {
    width: 100%;
    align-items: center;

    &__wrapper {
      width: 100%;
      justify-content: center;
      align-items: center;
    }
  }

  .liked-card {
     &__details{
      &--price{
        font-size: 1.3rem;
      }
     }
  }

  .liked-card-match-coloured-circle {
    display: none;
  }


  .liked-card-match {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;

    &__circle {
      position: static;
    }
  }

 
  .liked-card-match-stamp {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: static;
    bottom: 0px;
    left: 0px;
    height: auto;
    padding: 0;
  
    &__number {
      color: black;
      font-family: "Poppins Bold";
      font-size: 2rem;
      margin: 0;
    }
  
    &__text {
      color: $black;
      font-family: "Poppins Light";
      font-size: 1rem;
    }
  
    &__percent {
      font-size: 1rem;
    }
  }

  .gallery {
    &__hero--header {
      text-align: center;
    }
    &__hero--title {
      text-align: center;
      font-size: 2rem;
    }
  }
}

.matches_page_heading {
  display:flex;
  justify-content:space-between;
  align-items:center;
  margin:2rem 0rem;

  @media only screen and (max-width: 550px) {
    flex-direction: column;
    justify-content: center;
  }

  &--text{
    @media only screen and (max-width: 550px) {
      font-size: 1rem;
    }
  }
}


.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 1rem 0rem;
  width: 100%;
}

.line {
  height: 2px;
  width: 40%;
  background-color: #f5f5f5;
}

.view-button {
  background-color: white;
  color: grey;
  border: 0.5px solid #cdcdcd;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px; /* Adjust font size */
  font-family: Arial, sans-serif;
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.view-button:hover {
  background-color: #f5f5f5;
  transform: scale(1.025);
}


